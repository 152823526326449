import { Component } from "react";
import { Modal, Box, Typography } from "@mui/material";
import parse from 'html-react-parser'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    marginRight: "2rem",
    maxWidth: "80vw",
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "white",
    color: "black",
    boxShadow: 24,
    p: 4,
};

class DrivingLicenseModal extends Component {
    getFormattedDescription = (text) => {
        document.getElementById("#description").innerHTML = text;
    }

    render = () => {
        return (
            <Modal
                open={this.props.show}
                onClose={this.props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {this.props.title}
                    </Typography>
                    {this.props.description.map((desc, i) => {
                        return (
                            <Box key={i} id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                                <div>
                                    <h4>{desc.title} </h4>
                                    <div id="description">{parse(desc.text)}</div>
                                </div>
                            </Box>
                        );
                    })}
                </Box>
            </Modal>
        );
    };
}

export default DrivingLicenseModal;