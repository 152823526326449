import { Box, Grid, Typography } from "@mui/material";
import "animate.css";
import "./CardMenu.css";

const CardMenu = (props) => {
    const navigateTo = () => {
        window.location.href = "/" + props.title.replace(" ", "").toLowerCase();
    }

    return (
        <Grid item xs={6} md={6} lg={4} display="flex" alignItems="center" justifyContent="center" sx={{minHeight: {xs: "15vh", md: "20vh"}}}>
            <Box className="box animate__animated animate__fadeIn animate_fast" onClick={navigateTo}>
                {props.icon}
                <Typography variant="h5" marginTop="1rem" sx={{ fontSize: { xs: "1rem", sm: "1.6rem" } }}>{props.title.replace("_", " ")}</Typography>
            </Box>
        </Grid>
    )
}

export default CardMenu;