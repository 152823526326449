import { Component } from "react";
import {
    Box,
    Container,
    Typography,
    Grid
} from "@mui/material";
import AOS from "aos";
import FilesTable from '../../Partials/FilesTable/FilesTable';
import OfficeCard from "../DrivingLicenses/OfficeCard";
import HelpIcon from '@mui/icons-material/Help';
import './Renew.css'

class Renew extends Component {
    componentDidMount = () => {
        AOS.init({
            once: true,
        });
        AOS.init({ disable: "mobile" });
        window.scrollTo(0, 0);
    };

    render = () => {
        return (
            <section className="renew">
                <div className="background"></div>
                <div className="card" data-aos="fade-up" data-aos-delay="200">
                    <Container>
                        <div className="card-section">
                            <Typography id="title" variant="h2" textAlign="center">
                                Rinnovo patenti
                            </Typography>
                            <Typography id="subtitle" variant="h5" textAlign="center">
                                <HelpIcon fontSize="large"/> Come fare
                            </Typography>
                            <Typography textAlign="center">
                                Presso l'Autoscuola si possono conseguire tutte le diverse
                                categorie di patente
                            </Typography>
                            <Typography textAlign="center" sx={{ marginBottom: "2rem" }}>
                                Per rinnovare la patente di guida è necessario sostenere una
                                visita medica, che può essere effettuata presso l' a.s.l., o
                                presso le nostre sedi
                            </Typography>
                            <Typography textAlign="center" sx={{ marginBottom: "2rem" }}>
                                <u>E' necessario prenotare e presentarsi con i seguenti documenti</u>: <br /><br /><b>Patente</b>, <b>Codice Fiscale</b> e <b>Foto</b>
                            </Typography>
                            <Typography textAlign="center" id="title" variant='h5'>
                                Orari visite mediche presso le nostre sedi:
                            </Typography>
                            <Box textAlign="center">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <OfficeCard
                                            place="Cuneo"
                                            days="Lunedì e Giovedi"
                                            hour="17:00-17:30"
                                            special="Venerdì 15:00-15:30"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Busca"
                                            days="Mercoledì (Ogni 15 giorni)"
                                            hour="18:00-18:30"
                                            special=" "
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Centallo"
                                            days="Mercoledì"
                                            hour="17:00-18:00"
                                            special=" "
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Peveragno"
                                            days="Martedì (Ogni 15 giorni)"
                                            hour="18:00-18:30"
                                            special=" "
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <div className="card-section">
                            <FilesTable />
                        </div>
                    </Container>
                </div>
            </section >
        );
    };
}

export default Renew;
