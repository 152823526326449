import { Component } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CardMenu from "./CardMenu";
import "animate.css";
import "./HomePageMenu.css";

import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ViewListIcon from '@mui/icons-material/ViewList';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import TimelineIcon from '@mui/icons-material/Timeline';
import ClearAllIcon from '@mui/icons-material/ClearAll';

class HomeMenu extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

render = () => {
  return (
    <section className="homepagemenu">
      <img className="logo" src="/img/insegna.png" alt="insegna" />
      <Box className="homepagebanner" textAlign="center" overflow="hidden" sx={{ width: { xs: "90%", md: "80%" }, padding: "1.4rem" }}>
        <Typography variant="h4" className="animate__animated animate__fadeIn animate_fast" sx={{ fontSize: { xs: "1.4rem", md: "2rem" } }}>Scopri L'Autoscuola Europa</Typography>
        <Grid container spacing={2}>
          <CardMenu title="Autoscuola" icon={<TimeToLeaveIcon sx={{ fontSize: { xs: "4rem", md: "4.5rem" } }} />} />
          <CardMenu title="Patente" icon={<ContactPageIcon sx={{ fontSize: { xs: "4rem", md: "4.5rem" } }} />} />
          <CardMenu title="Corsi" icon={<ClearAllIcon sx={{ fontSize: { xs: "4rem", md: "4.5rem" } }} />} />
          <CardMenu title="Punti" icon={<TimelineIcon sx={{ fontSize: { xs: "4rem", md: "4.5rem" } }} />} />
          <CardMenu title="CQC" icon={<ViewListIcon sx={{ fontSize: { xs: "4rem", md: "4.5rem" } }} />} />
          <CardMenu title="Rinnovo_Patenti" icon={<RecentActorsIcon sx={{ fontSize: { xs: "4rem", md: "4.5rem" } }} />} />
        </Grid>
      </Box>
      {/* <Typography align="center" className="homepagemenufooter" sx={{position: {md: "absolute"}, bottom: {md: 20}}}>Nuova Autoscuola Europa Snc di Ferrero Paolo, Giordana Valerio, Grosso Danilo, Satta Remo - Via Asilo, 5 Cuneo (CN) - P.IVA 00214560047 - Privacy - Note legali</Typography> */}
    </section>
  );
};
}

export default HomeMenu;
