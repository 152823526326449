import { Component } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import InfoForm from "./Forms/InfoForm";
// import QuotationForm from "./Forms/QuotationForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class PointsAccordion extends Component {
  render = () => {
    return (
      <div>
{/*         <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Richiesta informazioni</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InfoForm />
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Richiesta Preventivo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QuotationForm />
          </AccordionDetails>
        </Accordion> */}
      </div>
    );
  };
}

export default PointsAccordion;
