import { Component } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

class ContactAccordion extends Component {
  render = () => {
    return (
      <Accordion defaultExpanded={this.props.open}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{this.props.place}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <Typography>{this.props.title}</Typography>
            <Typography>{this.props.address}</Typography>
            <Typography>{this.props.phone}</Typography>
            <br />
            <Box>
              <Button sx={{marginTop: ".5rem", marginRight: "1rem", minWidth:"150px"}} target="_blank" href={this.props.maps} variant="contained">
                <AddLocationIcon /> Raggiungici
              </Button>
              <Button sx={{marginTop: ".5rem", marginRight: "1rem", minWidth:"150px"}} href={"tel:" + this.props.phone} variant="contained">
                <LocalPhoneIcon /> {this.props.phone}
              </Button>
              <Button sx={{marginTop: ".5rem", marginRight: "1rem", minWidth:"150px"}}  href={"mailto:" + this.props.email} variant="contained">
                <EmailIcon /> Mail
              </Button>
            </Box>
          </ul>
        </AccordionDetails>
      </Accordion>
    );
  };
}

export default ContactAccordion;
