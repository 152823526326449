import { Component } from 'react';
import { Card, Typography, Link } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

class OfficeCard extends Component {
    render = () => {
        return (
            <Link target="_blank" href={this.props.maps} underline="none" sx={{ cursor: "pointer" }}>
                <Card sx={{ padding: "2rem", height: "100%" }}>
                    <Typography variant="h5"><WatchLaterIcon fontSize="large" /></Typography>
                    <Typography variant="h5">{this.props.place}</Typography>
                    <Typography>{this.props.address}</Typography>
                    <Typography>{this.props.days}</Typography>
                    <Typography>{this.props.hour}</Typography>
                    <Typography>{this.props.testDay}</Typography>
                    <Typography>{this.props.phone}</Typography>
                </Card>
            </Link>
        )
    }
}

export default OfficeCard;