import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// Our Components
import Navbar from "../Partials/Navbar/Navbar";
import Footer from "../Partials/Footer/Footer";
import BottomNavbar from "../Partials/BottomNavbar/BottomNavbar";
import HomePageMenu from "../Pages/HomePage/HomePageMenu";
import SchoolDrive from "../Pages/SchoolDrive/SchoolDrive";
import DrivingLicenses from "../Pages/DrivingLicenses/DrivingLicenses";
import Courses from "../Pages/Courses/Courses";
import Points from "../Pages/Points/Points";
import Contact from "../Pages/Contact/Contact";
import Login from "../Private/Login/Login";
import Dashboard from "../Private/Dashboard/Dashboard";
import Privacy from "../Pages/Privacy/Privacy";
import LegalNotice from "../Pages/LegalNotice/LegalNotice";
import Renew from "../Pages/Renew/Renew";
import Cqc from "../Pages/CQC/Cqc"
import Dashimage from "../Private/DashImage/Dashimage";


const App = () => {
  const [hideFooter, setHideFooter] = useState(false);
  useEffect(() => {
    if (window.location.href.includes("/dash")) {
      setHideFooter(true)
    } else {
      setHideFooter(false);
    }
  }, []);

  return (
    <React.StrictMode>
      {!hideFooter && <Navbar />}
      <Routes>
        <Route path="/" element={<HomePageMenu />} />
        <Route path="/autoscuola" element={<SchoolDrive />} />
        <Route path="/patente" element={<DrivingLicenses />} />
        <Route path="/corsi" element={<Courses />} />
        <Route path="/punti" element={<Points />} />
        <Route path="/rinnovo_patenti" element={<Renew />} />
        <Route path="/cqc" element={<Cqc />} />
        <Route path="/contatti" element={<Contact />} />
        <Route path="/areaprivata" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashimage" element={<Dashimage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/legalNotice" element={<LegalNotice />} />
        <Route path='*' element={<HomePageMenu />}/>
      </Routes>
      {!hideFooter && <Footer />}
      {hideFooter && <BottomNavbar />}
    </React.StrictMode>
  );
};

export default App;
