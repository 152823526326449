import { Component } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import PointsAccordion from "./PointsAccordion";
import { styled } from "@mui/material/styles";
import "./Points.css";
import AOS from "aos";
import FilesTable from '../../Partials/FilesTable/FilesTable';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, points, duration, lessons) {
  return { name, points, duration, lessons };
}

const rows = [
  createData("A-B B+E", "max. 6", "12 ore", "6 da 2 ore"),
  createData("Categorie superiori", "max. 9", "18 ore", "9 da 2 ore"),
];

class Points extends Component {
  componentDidMount = () => {
    AOS.init({
      once: true,
    });
    AOS.init({ disable: "mobile" });
    window.scrollTo(0, 0);
  };

  render = () => {
    return (
      <section className="Points">
        <div className="background"></div>
        <div className="card" data-aos="fade-up" data-aos-delay="200">
          <Container>
            <div
              className="card-section"
            >
              <Typography textAlign="center" id="title" variant="h2">
                Punti
              </Typography>
            </div>
            <div>
              <Typography>
                Punti In seguito all'introduzione della patente a punti (Decreto
                del Ministro delle infrastrutture e dei trasporti del 29 luglio
                2003), è possibile rientrare in possesso dei punti persi o di
                parte di essi mediante la frequentazione degli appositi corsi di
                I corsi di recupero dei punti prevedono lezioni teoriche
                riguardanti il codice della strada e le fondamentali norme di
                comportamento.
              </Typography>
            </div>
            <div className="card-section">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Categoria Patente</StyledTableCell>
                      <StyledTableCell align="right">
                        Recupero punti
                      </StyledTableCell>
                      <StyledTableCell align="right">Durata</StyledTableCell>
                      <StyledTableCell align="right">
                        Numero lezioni
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.points}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.duration}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.lessons}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              className="card-section"
            >
              <Typography>
                E' possibile iscriversi ai corsi solo dopo aver ricevuto la
                COMUNICAZIONE dell'avvenuta decurtazione dei punti da parte del
                Dipartimento dei Trasporti Terrestri. Non è possibile
                frequentare piu' di un corso per ogni comunicazione ricevuta.
                Col numero verde 848 782 782 (munito del numero della tua
                patente di guida) puoi saper in tempo reale il saldo dei punti
                persi. Per informazioni sui corsi:
              </Typography>
            </div>
            <div className="card-section" /* data-aos="fade-up" data-aos-delay="600" */>
              <FilesTable title="Calendari"/>
            </div>
            <div
              className="card-section"
            >
              <PointsAccordion title="Richiesta Preventivo" />
            </div>
          </Container>
        </div >
      </section >
    );
  };
}

export default Points;
