import { Component } from 'react';
import { Container, Box, Grid, Typography, Link } from '@mui/material';
import './SchoolDrive.css';
import AOS from 'aos';

import FilesTable from '../../Partials/FilesTable/FilesTable';
import CarCard from './CarCard';
import OfficeCard from './OfficeCard';

import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BusinessIcon from '@mui/icons-material/Business';
import CarRentalIcon from '@mui/icons-material/CarRental';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ParkIcon from '@mui/icons-material/Park';
import CardMembershipIcon from '@mui/icons-material/CardMembership';


class SchoolDrive extends Component {
    componentDidMount = () => {
        AOS.init({
            once: true
        });
        AOS.init({ disable: 'mobile' });
        window.scrollTo(0, 0);
    };

    render = () => {
        return (
            <section className="schooldrive">
                <div className="background"></div>
                <div className="card" data-aos="fade-up" data-aos-delay="200">
                    <Container >
                        <div className="card-section" /* data-aos="fade-up" data-aos-delay="600" */>
                            <Typography textAlign="center" id="title" variant="h2">Autoscuola Europa</Typography>
                        </div>
                        <div className="card-section" /* data-aos="fade-up" data-aos-delay="600" */>
                            <FilesTable title="Ultimi documenti caricati" />
                        </div>
                        <div className="card-section" /* data-aos="fade-up" data-aos-delay="800" */>
                            <Typography id="subtitle" variant="h5"><BusinessIcon fontSize="large" /> Le nostre sedi</Typography>
                            <Box>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Cuneo"
                                            address="Via Asilo, 5 12100 - Cuneo"
                                            image="/img/cuneo.webp"
                                            days="Lunedì, Martedì, Mercoledì, Giovedì, Venerdì"
                                            hour="08:15-12:15, 13:45-18:30"
                                            maps="https://goo.gl/maps/ZNxGzECJymxtFmmUA"
                                            phone="Tel. 0171 65804"
                                            modalName="modalCN"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Boves"
                                            address="Via Castello di Godego, 1 12012 - Boves (CN)"
                                            image="/img/boves6.webp"
                                            days="Lunedì, Martedì, Mercoledì, Giovedì, Venerdì"
                                            hour="16:30-19:30"
                                            maps="https://goo.gl/maps/nScCNGR63KH8B79BA"
                                            phone="Tel. 0171 380442"
                                            modalName="modalBV"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Busca"
                                            address="Corso Giovanni XXIII, 14 12022 - Busca (CN)"
                                            image="/img/busca.webp"
                                            days="Lunedì, Martedì, Mercoledì, Giovedì, Venerdì"
                                            hour="16:15-19:15"
                                            maps="https://goo.gl/maps/G8fatAedoUdwmqgn6"
                                            phone="Tel. 0171 945145"
                                            modalName="modalBU"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Centallo"
                                            address="Via Roata Chiusani, 27 12044 - Centallo (CN)"
                                            image="/img/centallo.webp"
                                            days="Lunedì, Martedì, Mercoledì, Giovedì, Venerdì"
                                            hour="17:00-20:00"
                                            maps="https://goo.gl/maps/KcUPcMK3W7DBwG6d9"
                                            phone="Tel. 0171 211515"
                                            modalName="modalCE"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Peveragno"
                                            address="Via Divisione Cuneense, 37 12016 - Peveragno (CN)"
                                            image="/img/peveragno.webp"
                                            days="Lunedì, Martedì, Mercoledì, Giovedì, Venerdì"
                                            hour="16:00-19:00"
                                            maps="https://goo.gl/maps/mNs4eud9aB4kK4E26"
                                            phone="Tel. 0171 339877"
                                            modalName="modalPE"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <OfficeCard
                                            place="Morozzo"
                                            address="Via Marconi, 30 12040 Morozzo (CN) "
                                            image="/img/morozzo.webp"
                                            days="Lunedì, Martedì, Mercoledì, Giovedì, Venerdì"
                                            hour="17:00-19:00"
                                            maps="https://goo.gl/maps/L5AqvF32K82XifH47"
                                            phone="Tel. 0171 772472"
                                            modalName="modalMO"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <div className="card-section" /* data-aos="fade-up" */>
                            <Typography id="subtitle" variant="h5"><CarRentalIcon fontSize="large" /> I nostri veicoli</Typography>
                            <Box id="cars" textAlign="right">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="TROCK"
                                            image="/img/trock.webp"
                                            color="#1976d2"
                                            license="*Per patenti B"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="Citroen C3"
                                            image="/img/C3.webp"
                                            color="#1976d2"
                                            license="*Per patenti B"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="Citroen C3"
                                            image="/img/c3bianca.webp"
                                            color="#1976d2"
                                            license="*Per patenti B"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title=" RENAULT CLIO 1.5dci"
                                            image="/img/clio.webp"
                                            color="#1976d2"
                                            license="*Per patenti B"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="VOLKSVAGEN POLO"
                                            image="/img/polo.webp"
                                            color="#1976d2"
                                            license="*Per patenti B"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="VOLKSVAGEN POLO"
                                            image="/img/polobianca.webp"
                                            color="#1976d2"
                                            license="*Per patenti B"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="OPEL VIVARO"
                                            image="/img/opelbe.webp"
                                            color="#1976d2"
                                            license="*Per patenti BE"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="BETA (50cc)"
                                            image="/img/amBeta.webp"
                                            color="#1976d2"
                                            license="*Per patenti AM"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="SYM CROX (50cc)"
                                            image="/img/amCrox50.webp"
                                            color="#1976d2"
                                            license="*Per patenti AM"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="HM (50cc)"
                                            image="/img/amHm.webp"
                                            color="#1976d2"
                                            license="*Per patenti AM"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="KTM DUKE (125cc)"
                                            image="/img/duke.webp"
                                            color="#1976d2"
                                            license="*Per patenti A1"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="SYM CROX (125cc)"
                                            image="/img/crox.webp"
                                            color="#1976d2"
                                            license="*Per patenti A1"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="HONDA CB500F"
                                            image="/img/honda.webp"
                                            color="#1976d2"
                                            license="*Per patenti A2"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="KAWASAKI ER6 (650cc)"
                                            image="/img/kawasaki.webp"
                                            color="#1976d2"
                                            license="*Per patenti A"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="AUTOCARRO RENAULT"
                                            image="/img/camion-renault.webp"
                                            color="#1976d2"
                                            license="*Per patenti C/CE"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="IVECO"
                                            image="/img/iveco2.webp"
                                            color="#1976d2"
                                            license="*Per patenti C1"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="IVECO EUROCARGO"
                                            image="/img/iveco.webp"
                                            color="#1976d2"
                                            license="*Per patenti C/CE"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CarCard
                                            title="AUTOBUS MERCEDES"
                                            image="/img/autobus_mercedes.webp"
                                            color="#1976d2"
                                            license="*Per patenti D"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <div className="card-section" /* data-aos="fade-up" */ >
                            <Box textAlign="center">
                                <Typography id="subtitle" variant="h5"><CardMembershipIcon fontSize="large" /> Politica della qualità</Typography>
                                <Typography>La professionalità, la qualità, la continua tendenza al miglioramento, in sinergia con la continua innovazione tecnologica, sono i punti di forza della nostra Autoscuola. La politica di qualità è quellla di fornire ai Clienti servizi perfettamente conformi a soddisfare le loro esigenze.</Typography>
                            </Box>
                        </div>
                        <div className="card-section" /* data-aos="fade-up" */ >
                            <Box textAlign="center">
                                <Typography id="subtitle" variant="h5"><ParkIcon fontSize="large" /> Politica ambientale</Typography>
                                <Typography>La nostra Autoscuola persegue costantemente politiche di massimo rispetto per l’ambiente, assicurando:</Typography>
                                <ul style={{ listStyle: 'none' }}>
                                    <li>Basso impatto ambientale</li>
                                    <li>Adeguamento alle condizioni di massima tutela dell’ambiente</li>
                                    <li>Prevenzione dell’inquinamento.</li>
                                </ul>
                                <Typography>Essere scelti ed apprezzati significa distinguersi non solo per la qualità del servizio ma anche per il rispetto dell’ambiente, pertanto è nostro costante impegno promuovere e diffondere la mentalità della tutela ambientale nell'interesse della collettività.</Typography>
                            </Box>
                        </div>
                        <div className="card-section" /* data-aos="fade-up" */>
                            <Typography id="subtitle" variant="h5"><EmojiPeopleIcon fontSize="large" />Contattaci</Typography>
                            <Box textAlign="center">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Link href="tel:0171-65804" underline="none">
                                            <Typography variant="h3"><LocalPhoneIcon fontSize="1rem" color="primary" /></Typography>
                                            <Typography variant="h5">TELEFONO</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Link target="_blank" href="https://goo.gl/maps/tnvYSoxyZS7hErqc9" underline="none">
                                            <Typography variant="h3"><AddLocationIcon fontSize="1rem" color="primary" /></Typography>
                                            <Typography variant="h5">MAPS</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Link href="mailto:info@autoscuolaeuropa.eu" underline="none">
                                            <Typography variant="h3"><MailIcon fontSize="1rem" color="primary" /></Typography>
                                            <Typography variant="h5">EMAIL</Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Container>
                </div >
            </section >
        )
    }
}

export default SchoolDrive;