import { Component } from "react";
import { Modal, Box, Typography, ImageList, ImageListItem } from "@mui/material";
import SchoolDriveModalFile from './SchoolDriveModalFile.json';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    marginRight: "2rem",
    maxWidth: "90vw",
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "white",
    color: "black",
    boxShadow: 24,
    p: 4,
};

const styleImg = {
    objectFit: "cover",
    height: "inherit"
}

class SchoolDriveModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arr: []
        };
    }

    componentDidMount = () => {
        let arrData = [];

        SchoolDriveModalFile.map((image) => {
            if (image['modal'] === this.props.modalName) {
                arrData.push(image);
            }
        });

        this.setState({ arr: arrData });
    }

    render = () => {
        return (
            <Modal
                open={this.props.show}
                onClose={this.props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ImageList sx={{ width: "auto", height: "auto" }} cols={2} rowHeight={164}>
                        {this.state.arr.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                    style={styleImg}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>

            </Modal >
        );
    };
}

export default SchoolDriveModal;