import { Component } from 'react';
import { Container, Box, Grid, Button, Typography } from '@mui/material';
import DrivingLicenseModal from './DrivingLicenseModal';
import DrivingLicenseDescriptions from './DrivingLicenseDescription.json';
import AOS from 'aos';
import './DrivingLicenses.css';
import FilesTable from '../../Partials/FilesTable/FilesTable';

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';

class DrivingLicenses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalAM: false,
            modalA: false,
            modalB: false,
            modalC: false,
            modalD: false,
            modalE: false,
            modalF: false,
        };
    }

    componentDidMount = () => {
        AOS.init({
            once: true,
        });
        AOS.init({ disable: "mobile" });
        window.scrollTo(0, 0);
    };

    handleOperation = (e) => {
        this.setState({ [e.target.name]: !e.target.value });
    };

    closeOperation = () => {
        this.setState({
            modalAM: false,
            modalA: false,
            modalB: false,
            modalC: false,
            modalD: false,
            modalE: false,
            modalCQC: false,
        });
    };

    render = () => {
        return (
            <section className="drivinglicenses">
                <div className="background"></div>
                <div className="card" data-aos="fade-up" data-aos-delay="200">
                    <Container>
                        <div className="card-section">
                            <Typography id="title" variant="h2" textAlign="center">
                                Patenti / CQC
                            </Typography>
                            <Typography variant="h5" textAlign="center">
                                Presso l'Autoscuola si possono conseguire tutte le diverse
                                categorie di patente
                            </Typography>
                        </div>
                        <div className="card-section">
                            <FilesTable title="Ultimi documenti caricati" />
                        </div>
                        <div className="card-section">
                            <Typography id="subtitle" variant="h5">
                                <AssignmentIndIcon fontSize="large" /> Requisiti
                            </Typography>
                            <Typography>
                                Per la guida di veicoli occorre essere idonei per requisiti
                                psico-fisici (art.119 Codice della Strada) e morali (art. 120
                                C.d.S.), oltre ad aver compiuto l'età minima prescritta (art.115
                                C.d.S.) per la categoria di patente richiesta. Non può ottenere
                                la patente di guida o l'autorizzazione ad esercitarsi alla guida
                                chi sia affetto da malattia fisica o psichica, deficienza
                                organica o minorazione psichica, anatomica o funzionale tale da
                                impedire di condurre con sicurezza veicoli a motore.
                                L'accertamento dei requisiti psico-fisici spetta a un medico
                                facente parte del Servizio Sanitario come il medico dei servizi
                                di base, oppure il medico militare, il medico delle Ferrovie
                                dello Stato, della Polizia, dei Vigili del Fuoco, del ministero
                                del Lavoro o della Previdenza Sociale (art.119 C.d.S.). Il
                                certificato non deve riportare data anteriore a 6 mesi dalla
                                presentazione della domanda.
                            </Typography>
                        </div>
                        <div className="card-section">
                            <Typography id="subtitle" variant="h5">
                                <FormatListBulletedIcon fontSize="large" /> Categorie
                            </Typography>
                            <Box className="categories">
                                <Grid container spacing="50">
                                    {DrivingLicenseDescriptions.map((data, i) => {
                                        return (
                                            <Grid item xs={6} md={3} key={i}>
                                                <Button
                                                    name={data.id}
                                                    variant="contained"
                                                    size="large"
                                                    sx={{ width: "100%", height: "5rem", flexDirection: "column" }}
                                                    onClick={this.handleOperation}
                                                >
                                                    <FeaturedPlayListIcon fontSize='large' />
                                                    {data.title}
                                                </Button>
                                                <DrivingLicenseModal
                                                    show={this.state[data.id]}
                                                    onClose={this.closeOperation}
                                                    title={data.title}
                                                    description={data.description}
                                                    vehicles={data.vehicles}
                                                    theoretical={data.theoretical}
                                                    documents={data.documents}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        </div>
                    </Container>
                </div>
            </section>
        );
    };
}

export default DrivingLicenses;
