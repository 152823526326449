import { Component } from "react";
import "./Courses.css";
import AOS from "aos";
import { Container, Typography, Box, Grid } from "@mui/material";
import OfficeCard from "./OfficeCard";

import SchoolIcon from "@mui/icons-material/School";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import FilesTable from "../../Partials/FilesTable/FilesTable";

class Courses extends Component {
  componentDidMount = () => {
    AOS.init({
      once: true,
    });
    AOS.init({ disable: "mobile" });
    window.scrollTo(0, 0);
  };

  render = () => {
    return (
      <section className="courses">
        <div className="background"></div>
        <div className="card" data-aos="fade-up" data-aos-delay="200">
          <Container>
            <div
              className="card-section">
              <Typography textAlign="center" id="title" variant="h2">
                Corsi conseguimento Patente / CQC
              </Typography>
            </div>
            <div
              className="card-section">
              <Typography id="subtitle" variant="h5">
                <SchoolIcon fontSize="large" /> I nostri corsi
              </Typography>
              <Box textAlign="center">
                <ul>
                  <li>Corsi conseguimento patenti A, B, C, D, E</li>
                  <li>
                    Corsi conseguimento patenti B Speciali (diversamente abili)
                  </li>
                  <li>
                    Corsi conseguimento Certificati Abilitazione Professionale
                  </li>
                  <li>Corsi revisione patente di tutte le categorie</li>
                  <li>
                    Corsi conseguimento Certificato Idoneità Guida Ciclomotori
                  </li>
                  <li>Corsi recupero punti patente</li>
                  <li>Guide di perfezionamento per titolari di patente</li>
                </ul>
                <ul>
                  <li>
                    Corsi conseguimento carta di qualificazione del conducente
                    (CQC) con frequenza mensile
                  </li>
                </ul>
              </Box>
            </div>
            {/* <div
              className="card-section">
              <Typography id="evidence-section">
                Tutti gli esami teorici e pratici di guida si svolgono presso la
                nostra sede e con veicoli di nostra proprietà. Solo gli esami
                che, per legge, devono necessariamente svolgersi presso la sede
                del Dipartimento dei Trasporti Terrestri.
              </Typography>
            </div> */}
            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                <FmdBadIcon fontSize="large" />
                Corso rinnovo C.Q.C
              </Typography>
              {/* <Typography>
                A febbraio 2022 inizierà un corso per il conseguimento della
                carta di qualificazione del conducente per i possessori di
                patente C CE D DE.
                Chi fosse interessato può richiedere
                maggiori informazioni o effettuare l'iscrizione al corso presso
                una delle nostre sedi. Le lezioni si tengono normalmente il
                venerdì dalle 19.00 alle 22:00 e il sabato dalle 8:00 alle 14:00
              </Typography> */}
            </div>
            <div className="card-section">
              <Typography textAlign="center">
                Presso le nostre sedi sono disponibili i calendari con le date
                precise per i corsi di rinnovo della CQC meci e persone che
                verranno effettuati nei prossimi mesi. Sono previsti corsi
                intensivi, serali, solo sabato.
                <br />I corsi vengono effettuati in collaborazione con il
                Consorzio Scuole Guida di Cuneo nella sede del centro di
                istruzione di San Rocco Castagnaretta. Per informazioni o
                iscrizioni telefonare al n° 0171 65804.
              </Typography>
              <Typography sx={{ marginTop: ".5rem", marginBottom: ".5rem" }} textAlign="center">E' possibile visionare i prossimi calendari di rinnovo scaricando i pdf sottostanti:</Typography>
            </div>
            <div className="card-section">

              <FilesTable />
            </div>

            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                <FmdBadIcon fontSize="large" />
                Corso conseguimento C.Q.C
              </Typography>
            </div>
            <div className="card-section">
              <Typography textAlign="center">
                E' previso un corso di conseguimento della carta di qualificazione del conducente merci/persone (CQC) ogni 6 mesi
              </Typography>
              <Typography sx={{ marginTop: ".5rem", marginBottom: ".5rem" }} textAlign="center">E' possibile visionare i prossimi calendari del prossimo corso scaricando il pdf sottostante:</Typography>
            </div>
            <div className="card-section">
              <FilesTable customPage="cqc" />
            </div>


            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                <SchoolIcon fontSize="large" /> Corso cronotachigrafo
              </Typography>
              <div>
                <Typography textAlign="center">
                  IN OTTEMPERANZA A QUANTO PREVISTO DALLA NORMATIVA EUROPEA IN
                  MATERIA DI CRONOTACHIGRAFO, STIAMO RACCOGLIENDO LE ADESIONI
                  PER I CORSI SUL CORRETTO USO E LO SCARICO DATI CON UTILIZZO DI
                  SIMULATORE E PROVE PRATICHE.
                  <br />
                  <br />
                  PER INFORMAZIONI CONTATTARE LA SEDE DI CUNEO.<br /> TEL 0171 65804 EMAIL info@autoscuolaeuropa.eu
                </Typography>
              </div>
            </div>
            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                <AccessTimeIcon fontSize="large" /> Orari dei corsi teorici patenti A/B
              </Typography>
              <Box textAlign="center">
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <OfficeCard
                      place="Cuneo"
                      days="Dal Lunedì al Venerdì"
                      hour="14:00-15:00 / 18:30-19:30"
                      testDay="Per conseguimento patenti superiori Martedì e Giovedì 19.30 / 20.30"
                      maps="https://goo.gl/maps/ZNxGzECJymxtFmmUA"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <OfficeCard
                      place="Boves"
                      days="Lunedì, Mercoledì, Giovedì, Venerdì"
                      hour="19:00-20:00"
                      // testDay="Martedì Prova Quiz 19:00-20:00"
                      maps="https://goo.gl/maps/nScCNGR63KH8B79BA"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <OfficeCard
                      place="Busca"
                      days="Lunedì, Martedì, Mercoledì, Giovedì"
                      hour="17:30-18:45"
                      // testDay=" "
                      maps="https://goo.gl/maps/G8fatAedoUdwmqgn6"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <OfficeCard
                      place="Centallo"
                      days="Lunedì, Martedì, Mercoledì, Giovedì"
                      hour="19:00-20:00"
                      // testDay=" "
                      maps="https://goo.gl/maps/KcUPcMK3W7DBwG6d9"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <OfficeCard
                      place="Peveragno"
                      days="Lunedì,  Mercoledì, Giovedì, Venerdì"
                      hour="17:45-18:45"
                      // testDay="Martedì 17:30-18:30 Prova Quiz"
                      maps="https://goo.gl/maps/mNs4eud9aB4kK4E26"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <OfficeCard
                      place="Morozzo"
                      days="Lunedì, Mercoledì, Giovedì"
                      hour="19:00-20:00"
                      // testDay="Martedì 19:00-20:00 Prova Quiz"
                      maps="https://goo.gl/maps/L5AqvF32K82XifH47"
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Container>
        </div>
      </section>
    );
  };
}

export default Courses;
