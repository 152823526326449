import Axios from 'axios';
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, BottomNavigation, BottomNavigationAction } from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';

const BottomNavbar = () => {
    const [active, setActive] = useState(window.location.href.split("/")[3] != null ? window.location.href.split("/")[3] : "dashboard")
    const handleLabel = (event, value) => {
        setActive(value);
    }

    const makeLogout = () => {
        Axios.post("/logout")
            .then(res => {
                if (res.data.loggedOut) window.location.href = "/";
            })
    }

    return (
        <Box sx={{ position: "fixed", width: "100%", bottom: 0, boxShadow: "0 0 2px black" }}>
            <BottomNavigation
                showLabels
                value={active}
                onChange={handleLabel}
            >

                <BottomNavigationAction component={RouterLink} to="/dashboard" value="documenti" label="Documenti" icon={<ArticleIcon />} />
                <BottomNavigationAction href="/" value="homepage" label="Home" icon={<HomeIcon />} />
                <BottomNavigationAction onClick={makeLogout} value="logout" label="Logout" icon={<LogoutIcon />} />
            </BottomNavigation>
        </Box>
    )
}

export default BottomNavbar;