import { Component } from 'react';
import { Box, Grid, TextField, TextareaAutosize, Button, Typography } from '@mui/material';
import Axios from 'axios';

class InfoForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstname: "",
            surname: "",
            email: "",
            phone: "",
            info: "",
            checkBtn: true,
            outcome: ""
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (this.state.firstname !== "" && this.state.surname !== "" && this.state.email !== "" && this.state.phone !== "" && this.state.info !== "") {
                this.setState({ "checkBtn": false });
            } else {
                this.setState({ "checkBtn": true });
            }
        });
    }

    submitForm = (e) => {
        e.preventDefault();
        Axios.post("/infoform", this.state)
            .then((res) => {
                if (res.data.outcome === true) {
                    this.setState({ "outcome": "Invio effettuato con successo" });
                } else {
                    this.setState({ "outcome": "Oh no! Qualcosa è andato storto" });
                }
            })
    }

    render = () => {
        return (
            <Box component="form" noValidate autoComplete="off">
                <Grid container width="40%" spacing={2} marginBottom="2rem">
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="standard-basic"
                            label="Nome"
                            name="firstname"
                            variant="standard"
                            value={this.state.firstname}
                            onChange={this.handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="standard-basic"
                            label="Cognome"
                            name="surname"
                            variant="standard"
                            value={this.state.surname}
                            onChange={this.handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} width="100%">
                        <TextField
                            id="standard-basic"
                            width="100% !important"
                            label="Email"
                            name="email"
                            variant="standard"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} width="100%">
                        <TextField
                            id="standard-basic"
                            width="100% !important"
                            label="Telefono"
                            name="phone"
                            variant="standard"
                            value={this.state.phone}
                            onChange={this.handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextareaAutosize name="info" placeholder="Desidero avere queste informazioni...*" minRows={15} cols={100} value={this.props.info} onChange={this.handleChange} />
                        <Button disabled={this.state.checkBtn} onClick={this.submitForm} variant="contained" sx={{ marginTop: "1rem" }}>Invia</Button>
                    </Grid>
                </Grid>
                <Typography sx={{ marginTop: "1rem" }}>{this.state.outcome}</Typography>
            </Box>
        )
    }
}

export default InfoForm;