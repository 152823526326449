import { Component } from "react";
import {
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import Axios from 'axios';

class QuotationForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      firstname: "",
      surname: "",
      city: "",
      email: "",
      phone: "",
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
      check6: false,
      check7: false,
      check8: false,
      check9: false,
      check10: false,
      check11: false,
      check12: false,
      check13: false,
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  submitForm = (e) => {
    e.preventDefault();
    Axios.post("/quotationform", this.state)
        .then((res) => {
            console.log("Esito" + res.outcome);
        })
  }


  render = () => {
    return (
      <Box component="form" noValidate autoComplete="off" onSubmit={this.submitForm}>
        <Grid container width="40%" spacing={2} marginBottom="2rem">
          <Grid item xs={12} md={6}>
            <TextField
              id="standard-basic"
              label="Nome"
              name="firstname"
              variant="standard"
              value={this.state.firstname}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="standard-basic"
              label="Cognome"
              name="surname"
              variant="standard"
              value={this.state.surname}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12} width="100%">
            <TextField
              id="standard-basic"
              width="100% !important"
              label="Città"
              name="city"
              variant="standard"
              value={this.state.city}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="standard-basic"
              label="Email"
              name="email"
              variant="standard"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="standard-basic"
              label="Telefono"
              name="phone"
              variant="standard"
              value={this.state.phone}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check1" checked={this.state.check1} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento patente A"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check2" checked={this.state.check2} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento patente B"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check3" checked={this.state.check3} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento patente C"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check4" checked={this.state.check4} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento patente D"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check5" checked={this.state.check5} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento patente E"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check6" checked={this.state.check6} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento patenti B Speciali (diversamente abili)"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check7" checked={this.state.check7} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento Certificati Abilitazione Professionale"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check8" checked={this.state.check8} onChange={this.handleCheckChange} />}
              label="Corsi revisione patente di tutte le categorie"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check9" checked={this.state.check9} onChange={this.handleCheckChange} />}
              label="Corsi conseguimento Certificato Idonieta' Guida Ciclomotori"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check10" checked={this.state.check10} onChange={this.handleCheckChange} />}
              label="Corsi recupero punti patente"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check11" checked={this.state.check11} onChange={this.handleCheckChange} />}
              label="Guide di perfezionamento per titolari di patente"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox name="check12" checked={this.state.check12} onChange={this.handleCheckChange} />}
              label="Corso di guida sicura per i neopatentati"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>
              N.B. il consenso e' necessario al fine dell'invio dati. Con
              l'invio del presente modulo, l'interessato dichiara inoltre di
              essere a conoscenza delle informazioni e diritti previsti dal
              decreto legislativo 30 giugno 2003 n. 196 recante il Codice in
              materia di protezione dei dati personali.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button type="submit" variant="contained" disabled={!this.state.check13} sx={{marginRight:"1rem"}}>Invia</Button>
            <FormControlLabel control={<Checkbox name="check13" onChange={this.handleCheckChange} />} label="Accetto" />
          </Grid>
        </Grid>
      </Box>
    );
  };
}

export default QuotationForm;
